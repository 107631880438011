<template>
  <v-form @submit.prevent="next" class="pa-4" ref="form">
    <p>Selecione os arquivos OFX de cada conta bancária</p>
    <v-table>
      <thead>
        <tr>
          <th class="text-left">Banco</th>
          <th class="text-left">Agência</th>
          <th class="text-left">Conta</th>
          <th class="text-left">Arquivo OFX</th>
          <th class="text-left">Total (IEPTB)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in form.banks" :key="item.number">
          <td>{{ item.name }}</td>
          <td>{{ item.agency }}</td>
          <td>{{ item.number }}</td>
          <td>
            <input
              type="file"
              palceholder="Selecione o arquivo OFX"
              accept=".ofx,.OFX"
              :key="item.number"
              :ref="'ofx' + item.number"
              @change="readOfx(i)"
            />
          </td>
          <td>{{ brl(item.ieptb_total / 100) }}</td>
        </tr>
      </tbody>
    </v-table>
    <div class="mt-16 d-flex">
      <v-btn color="primary" size="large" @click="$emit('prev')" variant="text">
        <v-icon>ph-arrow-left</v-icon>
        <span>Voltar</span>
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        size="large"
        type="submit"
        :disabled="form.banks.find((a) => !a.ofx_result)"
      >
        <span>Próximo</span>
        <v-icon>ph-arrow-right</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { FormBindind } from "../../mixins/form";
import { rules } from "../../utils/rules";
import { brl } from "../../utils/brl";
import { ofx2Json } from "../../services/ofx";
import moment from "moment";
import { api } from "@/services/api";

export default {
  name: "FinancialCloserStep2",
  mixins: [FormBindind],
  data() {
    return {
      brl,
      rules,
      a: "",
      form: {
        banks: [
          { name: "341", agency: "9278", number: "99734-2" },
          { name: "237", agency: "1901", number: "83333-9" },
          { name: "237", agency: "1901", number: "53410-2" },
        ],
        ofxs: {},
      },
    };
  },
  computed: {
    date() {
      return moment(this.form.date.split("/").reverse().join("-"));
    },
  },
  methods: {
    async next() {
      if (
        this.form.banks.find(
          (item) => !this.$refs["ofx" + item.number][0].files[0]
        )
      )
        return alert("Insira todos os OFX");

      await api.post(
        "/bank/transaction",
        Object.values(this.form.banks)
          .map((a) =>
            (
              this.fixOfxList(
                a.ofx_result.OFX?.BANKMSGSRSV1?.BANKTRANLIST?.STMTTRN
              ) || []
            ).map((b) => ({
              ...b,
              bank: { name: a.name, agency: a.agency, number: a.number },
            }))
          )
          .flat()

          .filter(
            (a) =>
              a.MEMO.toUpperCase().indexOf("IEPTB") >= 0 &&
              a.TRNTYPE === "CREDIT"
          )
          .map((a) => ({
            amount: this.fixValue(a.TRNAMT),
            date: new Date(
              [
                a.DTPOSTED.substring(0, 4),
                a.DTPOSTED.substring(4, 6),
                a.DTPOSTED.substring(6, 8),
              ].join("-")
            ),
            description: a.MEMO,
            status: "WAITING",
            bank: a.bank,
          }))
          .filter((a) => a.date)
      );

      this.$emit("next");
    },
    async readOfx(i) {
      const item = this.form.banks[i];
      this.form.banks[i].ofx = this.$refs["ofx" + item.number][0].files[0];
      this.form.banks[i].ofx_result = await ofx2Json(this.form.banks[i].ofx);
      this.form.banks[i].ieptb_total = await this.sumIEPTB(
        this.form.banks[i].ofx_result
      );
      console.log(
        "this.form.banks[i].ieptb_total",
        this.form.banks[i].ieptb_total
      );
    },
    fixValue(v) {
      return Math.round(`${v}`.replace(/,/g, ".") * 100);
    },
    async sumIEPTB(result) {
      // const whitelist = [
      //   "TED 237.2268IEPTB MG",
      //   "341-CUSTAS CARTORARIAS",
      //   "DOC 422.0071BANCO SAFRA",
      //   "TED 104.0000CAIXA ECON F",
      //   "TED-TRANSF ELET DISPON REMET.CAIXA ECONOMICA FEDE",
      //   "TED 00237.2268IEPTB MG",
      //   "TED 237.2268IEPTB MG",
      //   "341-CUSTAS CARTORARIAS",
      //   "DOC 422.0071BANCO SAFRA",
      //   "TED 104.0000CAIXA ECON F",
      //   "TED-TRANSF ELET DISPON REMET.CAIXA ECONOMICA FEDE",
      //   "TED 00237.2268IEPTB MG",
      //   "TED 745.0000BANCO CITIBA",
      // ].map((a) => a.toUpperCase());
      const rows =
        this.fixOfxList(result.OFX?.BANKMSGSRSV1?.BANKTRANLIST?.STMTTRN) || [];

      const selected = rows.filter(
        (a) =>
          a.MEMO.toUpperCase().indexOf("IEPTB") >= 0 && a.TRNTYPE === "CREDIT"
      );

      return selected.reduce(
        (total, item) => total * 1 + this.fixValue(item.TRNAMT) * 1,
        0
      );
    },
    fixOfxList(v) {
      if (v.TRNTYPE) return [v];
      return v;
    },
  },
};
</script>

<style></style>
