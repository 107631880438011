import { ITransaction } from "@/interfaces/transaction.inetrface";
import { api } from "./api";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const splitArray = (array: any[], size: number) => {
  const arrayOfArrays = [];
  for (let i = 0; i < array.length; i += size) {
    arrayOfArrays.push(array.slice(i, i + size));
  }

  return arrayOfArrays;
};

export const loadLot = async (fileName: string, list: ITransaction[]) => {
  const splited = splitArray(list, 5000);

  for (let i = 0; i < splited.length; i++) {
    const subList = splited[i];
    await api.post("transaction/lot", { fileName, data: subList });
  }
};
