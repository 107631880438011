import { csvParse } from "d3";
import xlsParser from "xls-parser";
import { getFileContent } from "./file";

console.log(csvParse);

export const csv2Json = async (file: File) => {
  const content = await getFileContent(file);
  return csvParse(content);
};

export const xls2Json = async (file: File) => {
  const data = await xlsParser.onFileSelection(file);
  const pages = Object.keys(data);
  if (pages.length > 1) {
    alert("Selecione uma planilha com apenas uma página");
    throw new Error(">1 pages");
  }

  return data[pages[0]].map((a: Record<string, string>) =>
    Object.fromEntries(Object.entries(a).map((b) => [b[0].trim(), b[1]]))
  );
};
