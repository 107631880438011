import { Module } from "vuex";
import { StoreData } from ".";
import { IBank } from "../interfaces/bank.interface";

export interface BankState {
  banks: IBank[];
}

const state = () => ({
  banks: [],
});

export const BankStoreModule: Module<BankState, StoreData> = {
  namespaced: true,
  state,
  getters: {},
  mutations: {
    SET_BANKS(state: BankState, payload: IBank[]) {
      state.banks = payload;
    },
    ADD_BANK(state: BankState, payload: IBank) {
      state.banks.push(payload);
    },
  },
  actions: {
    fetchBanks({ commit }) {
      commit("SET_BANKS", [
        {
          name: "Banco Bradesco",
          agency: 3123,
          number: "61781",
          _id: "1",
        },
        {
          name: "Banco do Brasil",
          agency: 3123,
          number: "61781",
          _id: "2",
        },
      ]);
    },
    addBank({ commit }, payload) {
      commit("ADD_BANK", {
        _id: Math.round(Math.random() * 500) + "",
        ...payload,
      });
    },
  },
  modules: {},
};
