<template>
  <v-app class="grey h-full">
    <v-navigation-drawer
      dark
      @update:modelValue="(v) => (drawer = v)"
      :modelValue="$vuetify.display.mdAndUp || drawer"
      :permanent="$vuetify.display.mdAndUp"
      app
      style="overflow: unset"
      :rail="$vuetify.display.mdAndUp && mini"
      v-model:mini-variant="dd"
      expand-on-hover
      color="primary"
      class="inn-sidebar"
    >
      <v-btn
        class="mt-11 inn-sidebar-btn d-none d-md-block"
        style="
          position: fixed;
          right: 0;
          transform: translate(50%);
          z-index: 10;
        "
        :class="mini ? btnprimary : ''"
        @click="close"
        color="primary"
        elevation="0"
        icon=" "
        size="x-small"
      >
        <v-icon size="17px">
          {{ "ph-caret-" + (mini ? "right" : "left") }}
        </v-icon>
      </v-btn>
      <div class="mt-12 mb-6 d-flex align-center flex-column justify-center">
        <img
          :src="assets.icon"
          style="width: 80%; height: 35px; object-fit: contain; margin: 0 auto"
          class="logo-icon"
        />
        <img
          :src="assets.logo"
          style="width: 80%; height: 35px; object-fit: contain; margin: 0 auto"
          class="logo"
        />
        <v-btn
          class="mt-6"
          rounded="pill"
          variant="outlined"
          color="white"
          @click="$refs['financial-closer-modal'].open = true"
        >
          Iniciar conciliação
        </v-btn>
      </div>
      <v-divider color="#ffffff40" class="mx-4" />

      <v-list
        dense
        bg-color="transparent"
        dark
        active-color="white"
        class="mx-2 my-6"
      >
        <template v-for="(items, i) in sidebarItems" :key="i">
          <v-list-item
            :disabled="items.disabled"
            :to="items.url"
            class="rounded-lg px-2 overflow-hidden"
            v-if="!items.submenu"
            style="color: white"
            content-class="d-flex"
          >
            <template v-slot:prepend>
              <v-icon class="mr-3">{{ items.icon }}</v-icon>
            </template>

            <v-list-item-title
              class="d-flex justify-between align-center rounded-lg"
            >
              {{ items.name }}
              <v-chip v-if="items.disabled" size="x-small" class="mx-2"
                >BREVE</v-chip
              >
            </v-list-item-title>
          </v-list-item>
          <v-list-group
            class="justify-end align-end overflow-hidden rounded-lg"
            v-else
            :key="i"
            active-class="bg-active primary--text"
            style="color: white"
            append-icon="ph-star"
          >
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                class="rounded-lg px-2 overflow-hidden"
                style="color: white"
                append-icon="ph-caret-down"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">
                    {{ items.icon }}
                  </v-icon>
                </template>

                <v-list-item-title
                  class="d-flex justify-between align-center rounded-lg"
                >
                  {{ items.name }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <v-list-item
              v-for="(subitem, i) in items.submenu"
              :key="i"
              :to="subitem.url"
            >
              <template v-slot:prepend>
                <v-icon size="12px" color="in"> ph-circle </v-icon>
              </template>
              <v-list-item-title v-text="subitem.name"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
      <v-divider color="#ffffff40" class="mx-4" />

      <div class="d-flex justify-center mt-6 hide-rail">
        <v-btn
          icon="ph-gear-six"
          rounded="lg"
          color="#00000040"
          class="text-white p-shadow-1-primary"
          to="/configuracoes"
        />
        <v-btn
          icon="ph-sign-out"
          rounded="lg"
          color="#00000040"
          class="text-white ml-4 p-shadow-1-primary"
          to="/sair"
        />
      </div>
    </v-navigation-drawer>
    <v-main class="ma-4 mx-2 mx-md-4">
      <financial-closer ref="financial-closer-modal" />

      <v-card
        class="p-shadow-1 d-flex justify-between inn-shadow flex align-center"
        style="border-radius: 16px"
        height="60px"
      >
        <v-btn
          class="d-md-none mx-3"
          icon="ph-list"
          elevation="0"
          variant="text"
          @click="drawer = true"
        />
        <v-spacer />
        <div class="d-flex justify-between align-center mx-0 h-full">
          <v-avatar class="ml-6 mr-2">
            <img
              src="https://cdn4.iconfinder.com/data/icons/user-people-2/48/5-512.png"
              alt="Meu Perfil"
              width="40"
              height="40"
            />
          </v-avatar>
          <div style="line-height: 1" class="mr-6">
            <p class="mb-0" v-text="$user.name" />
            <!-- <caption class="text-caption">
              Leilooa
            </caption> -->
          </div>
        </div>
      </v-card>

      <!-- Provides the application the proper gutter -->
      <v-container fluid class="pl-md-10 pr-md-10" style="margin-bottom: 100px">
        <!-- If using vue-router -->
        <router-view :key="$route.path"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import icon from "../../assets/icon.png";
import logo from "../../assets/logo_light.png";
import FinancialCloser from "../../components/FinancialCloser/index.vue";

export default {
  name: "MainLayout",
  components: { FinancialCloser },
  data: () => ({
    selectedItem: 1,
    mini: localStorage.mini === "true",
    drawer: false,
    btnprimary: "primary",
    btnwhite: "white",
    dd: false,

    assets: {
      icon,
      logo,
    },
  }),
  created() {
    this.$store.dispatch("banks/fetchBanks");
  },
  methods: {
    close() {
      if (this.$vuetify.display.mdAndUp === true) {
        localStorage.mini = !this.mini;
        this.mini = !this.mini;
      } else {
        this.drawer = false;
      }
    },
  },
  computed: {
    user() {
      return {}; //this.$store.state.auth.logged;
    },
    sidebarItems() {
      const result = [
        {
          url: "/conciliacao",
          name: "Conciliação",
          slug: "dashboard",
          icon: "ph-chart-line",
          abilityName: "dashboard",
        },
        {
          name: "Carga de atos",
          slug: "atos",
          icon: "ph-math-operations",
          abilityName: "upload",
          url: "/atos",
        },
        {
          name: "Carga OFX",
          slug: "ofx",
          icon: "ph-bank",
          abilityName: "upload",
          url: "/ofx",
        },
      ];

      if (this.$user.role === "admin")
        result.push({
          url: "/validacao",
          name: "Validar baixas",
          slug: "Validação",
          icon: "ph-check-circle",
          abilityName: "users",
        });

      result.push({
        url: "/usuarios",
        name: "Usuários",
        slug: "usuarios",
        icon: "ph-users-three",
        abilityName: "users",
        disabled: true,
      });

      return result;
    },
  },
};
</script>

<style lang="scss">
.bg-active {
  &:before {
    background: var(--v-primary-base);
  }
}

.v-navigation-drawer:not(.v-navigation-drawer--mini-variant) {
  .bg-active {
    box-shadow: inset -30px 0 0 -23px;
  }
}

.v-list-group--active {
  background: #fafafa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  padding-bottom: 10px;
}

.inn-sidebar-btn.inn-sidebar-btn {
  box-shadow: 0 0 0 3px #c6c9d3 !important;
}

.inn-sidebar .inn-sidebar-btn {
  opacity: 0;
}

.inn-sidebar:hover .inn-sidebar-btn {
  opacity: 1;
}

.inn-sidebar .logo {
  display: none;
}

.inn-sidebar:not(.v-navigation-drawer--rail),
.inn-sidebar.v-navigation-drawer--is-hovering {
  .logo-icon {
    display: none;
  }
  .logo {
    display: block;
  }
}

.inn-sidebar.v-navigation-drawer--rail:not(.v-navigation-drawer--is-hovering) {
  .v-list-group__items {
    .v-list-item-content {
      display: none;
    }
  }
  .hide-rail {
    opacity: 0;
  }
}

.inn-sidebar {
  box-shadow: #00000030 10px 0px 30px 12px;
  // position: fixed !important;
  // max-height: 100vh;
  // top: 0;
  // left: 0;
  div.v-list-group__items {
    --indent-padding: -2px;
  }
}
</style>
