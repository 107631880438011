import { app } from "../main";
import { Module } from "vuex";
import { StoreData } from ".";
import { IUser } from "../interfaces/user.interface";

export interface AuthState {
  user?: IUser;
}

const state = () => ({
  user: undefined,
});

export const AuthStoreModule: Module<AuthState, StoreData> = {
  namespaced: true,
  state,
  getters: {},
  mutations: {
    SET_USER(state: AuthState, payload: IUser) {
      state.user = payload;
      app.config.globalProperties.$user = state.user;
    },
  },
  actions: {
    signUp({ commit }) {
      commit("SET_USER", null);
    },
  },
  modules: {},
};
