import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LayoutMain from "../views/layout/main.vue";
import Login from "../views/Login.vue";
import Atos from "../views/Upload/Atos.vue";
import OfxUpload from "../views/Upload/Ofx.vue";
import ArqBanc from "../views/Conciliacao/ArqBanc.vue";
import Validation from "../views/Validation.vue";
import { AuthMiddleware } from "../middleware/auth.middleware";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "LayoutMain",
    component: LayoutMain,
    beforeEnter: AuthMiddleware,
    children: [
      {
        path: "/",
        redirect: "/conciliacao",
      },
      {
        path: "/atos",
        name: "Atos",
        component: Atos,
      },
      {
        path: "/ofx",
        name: "OFX",
        component: OfxUpload,
      },
      {
        path: "/conciliacao",
        name: "ConciliacaoView",
        component: ArqBanc,
      },
      {
        path: "/validacao",
        name: "Validation",
        component: Validation,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async () => {
  // try {
  //   await getMe();
  // } catch {
  //   console.log("NÃO AUTENTICADO");
  // }
  return true;
});

export default router;
