import { api } from "./api";

export const getHolidaysApi = async (year: string) => {
  if (localStorage[`holidays_${year}`])
    return JSON.parse(localStorage[`holidays_${year}`]);

  const holidays: Record<string, string> = {};

  const { data } = await api.get(
    "https://brasilapi.com.br/api/feriados/v1/" + year
  );

  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    holidays[row.date] = row.name;
  }

  localStorage.setItem(`holidays_${year}`, JSON.stringify(holidays));

  return holidays;
};

// export const getHolidays = {
//     const yean
// }
