<template>
  <div class="upload-doc">
    <div>
      <div v-bind="getRootProps()">
        <input v-bind="getInputProps()" class="d-none" />
        <div
          v-if="!myFiles || myFiles.length === 0"
          style="
            background-color: #f1f0ef;
            color: #555;
            cursor: pointer;
            height: 200px;
          "
          class="d-flex align-center flex-column justify-center"
        >
          <i
            class="ph-upload-simple v-icon notranslate v-theme--default v-icon--size-default"
            style="font-size: 30px"
          />
          Arraste o seu documento <br /><span class="text-caption">ou</span>
          <span class="filepond--label-action">Procure</span>
        </div>
        <div
          v-else
          style="
            background-color: #f1f0ef;
            color: #555;
            cursor: pointer;
            height: 200px;
          "
          class="d-flex flex-column"
        >
          <div class="d-flex justify-end pa-4" @click="myFiles = null">
            <v-icon>ph-x</v-icon>
          </div>
          <v-spacer />
          <div class="flex-grow-1 d-flex align-center flex-column">
            <v-icon>ph-file</v-icon>
            <span>{{ myFiles[0].name }}</span>
          </div>
          <v-spacer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
export default {
  name: "UploadDoc",
  setup(props) {
    const onDrop = (...args) => {
      window.a3324650cOnDrop(...args);
    };

    const { getRootProps, getInputProps, ...rest } = useDropzone({
      onDrop,
      accept: props.accept,
    });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  data: function () {
    return { myFiles: null };
  },
  created() {
    window.a3324650cOnDrop = (acceptFiles) => {
      this.myFiles = acceptFiles;
      this.aa(acceptFiles);
    };
  },
  props: ["accept"],
  methods: {
    aa(v) {
      this.$emit("update:modelValue", v[0] || undefined);
    },
    reset() {
      this.myFiles = null;
    },
  },
};
</script>

<style lang="scss">
.upload-doc {
  .filepond--root,
  .filepond--root .filepond--drop-label {
    height: 200px;
  }
}

.filepond--credits {
  display: none;
}
</style>
