<template>
  <v-dialog v-model="open">
    <v-card
      width="95vw"
      height="90vh"
      max-height="700px"
      rounded="xl"
      scrollable
    >
      <v-row align-center style="flex-wrap: nowrap">
        <v-col cols="2">
          <v-card
            flat
            height="100%"
            color="#ddd"
            class="d-flex flex-column justify-center"
          >
            <v-list bg-color="transparent">
              <v-list-item
                v-for="(item, i) in steps"
                :key="i"
                :style="{ opacity: i <= step ? 1 : 0.5 }"
              >
                <template v-slot:prepend>
                  <v-icon class="mr-3">{{ item.icon }}</v-icon>
                </template>

                <v-list-item-title
                  class="d-flex justify-between align-center rounded-lg"
                >
                  {{ item.label }}
                  <v-icon
                    color="success-darken-2"
                    size="14px"
                    class="ml-2"
                    v-if="i < step"
                  >
                    ph-check
                  </v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col col="10" class="d-flex flex-column justify-center">
          <h1 class="mt-4">Conciliação de caixa</h1>
          <component
            :is="steps[step].component"
            v-model="data"
            @next="step++"
            @prev="step--"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import OFX from "./OFX.vue";
import Atos from "./Atos.vue";
import Audit from "./Audit.vue";

export default {
  name: "FinancialCloser",
  data() {
    return {
      ofx: {},
      open: false,
      step: 1,
      data: {},
      steps: [
        {
          label: "Atos Praticados",
          icon: "ph-plus-minus",
          component: Atos,
        },
        {
          label: "Carga OFX",
          icon: "ph-list-bullets",
          component: OFX,
        },
        { label: "Finalizado", icon: "ph-check", component: Audit },
      ],
    };
  },
};
</script>

<style></style>
