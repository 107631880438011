<template>
  <div>
    <div class="mb-6">
      <h1>Validação de baixas</h1>
      <p>Use os botões de aprovar/recusar.</p>
    </div>
    <div style="width: 100%; max-width: 600px">
      <v-card
        v-for="(item, i) in data"
        :key="i"
        elevation="10"
        class="mb-4 d-flex justify-space-between"
        rounded="xl"
      >
        <v-card-text>
          <h1>{{ format.date(item.date) }}</h1>
          <p
            class="mt-2"
            v-for="(value, key) in {
              Banco: banks[item.bank].name,
              Agência: banks[item.bank].agency,
              Conta: item.bank,
            }"
            :key="key"
          >
            {{ key }}: {{ value }}
          </p>
        </v-card-text>

        <v-card-text class="text-right">
          <h2>
            {{
              format.brl(
                Math.abs(
                  (item.auditData.atos || 0) - (item.auditData.bank || 0)
                ) / 100
              )
            }}
          </h2>

          <p
            class="mt-2"
            v-for="(value, key) in {
              Banco: format.brl(item.auditData.bank / 100),
              Atos: format.brl(item.auditData.atos / 100),
            }"
            :key="key"
          >
            {{ key }}: {{ value }}
          </p>
          <div class="d-flex justify-end mt-4">
            <v-btn color="error" size="small" variant="text" @click="refuse(i)">
              Recusar
            </v-btn>
            <v-btn
              color="success"
              size="small"
              variant="flat"
              @click="approve(i)"
            >
              Aprovar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

      <div v-if="data === undefined" class="d-flex justify-center">
        <v-progress-circular indeterminate color="primary darken-4" />
      </div>
      <div v-else-if="data.length === 0" style="color: #999">
        <p>vazio.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { approveAudit, getValidation, refuseAudit } from "@/services/audit";
import * as format from "../utils/format.util";
import { getAllBanks } from "@/services/bank";

export default {
  name: "ValidationPage",
  data: () => ({ data: undefined, format, banks: {} }),
  created() {
    getAllBanks().then(async (a) => {
      for (let i = 0; i < a.length; i++) {
        const item = a[i];
        this.banks[item.number] = item;
      }
      if (!this.data) this.data = await getValidation();
    });
  },
  methods: {
    approve(i) {
      const row = this.data[i];
      this.confirm("Tem certeza que deseja aprovar?", ({ isConfirmed }) => {
        console.log(isConfirmed);
        if (isConfirmed) {
          this.removeItem(i);
          this.$swal("Aprovado com sucesso!", "", "success");

          approveAudit(row._id);
        }
      });
    },
    refuse(i) {
      const row = this.data[i];
      this.confirm("Tem certeza que deseja recusar?", ({ isConfirmed }) => {
        console.log(isConfirmed);
        if (isConfirmed) {
          this.removeItem(i);
          this.$swal("Recusado com sucesso!", "", "success");
          refuseAudit(row._id);
        }
      });
    },
    removeItem(index) {
      this.data.splice(index, 1);
    },
    confirm(title, cb) {
      this.$swal({
        title,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(cb);
    },
  },
};
</script>

<style></style>
