<template>
  <div
    style="
      background: linear-gradient(180deg, #ffffff 0%, #e4e1fd 100%);
      min-height: 100vh;
    "
    class="d-flex align-center justify-center flex-column"
  >
    <div style="width: 90%; max-width: 200px">
      <v-img :src="assets.logo" :aspect-ratio="500 / 114" />
    </div>

    <div class="text-center mt-6">
      <h3>Seja bem-vindo!</h3>
      <p class="text-uppercase">Central de conciliação</p>
    </div>

    <v-form @submit.prevent="submit" ref="form">
      <v-card class="mt-6 pa-4" width="95vw" max-width="380px" rounded="lg">
        <p class="text-caption text-center mb-2">
          Digite o seu e-mail e senha para acessar
        </p>
        <v-text-field
          variant="filled"
          bg-color="#00000015"
          v-model="form.email"
          label="E-mail"
          :rules="[rules.required, rules.email]"
          :disabled="loading"
        />
        <v-text-field
          variant="filled"
          bg-color="#00000015"
          v-model="form.password"
          label="Senha"
          :rules="[rules.required]"
          :disabled="loading"
        />
        <div class="d-flex justify-space-between align-center">
          <span
            class="text-caption text-primary"
            style="cursor: pointer"
            @click="recoveryPassoword = true"
          >
            Esqueceu sua senha?
          </span>
          <v-btn
            rounded="pill"
            color="primary"
            type="submit"
            :loading="loading"
          >
            Acessar
          </v-btn>
        </div>
      </v-card>
    </v-form>
    <v-dialog v-model="recoveryPassoword">
      <v-card>
        <v-card-title> Esqueceu sua senha? </v-card-title>
        <v-card-text>
          Por segurança, peça ao administrador que redefina uma senha para a sua
          conta
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn variant="text" @click="recoveryPassoword = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { LogIn } from "@/services/auth";
import logo from "../assets/logo.png";
import { rules } from "../utils/rules";

export default {
  name: "LoginView",
  data() {
    return {
      rules,
      assets: { logo },
      form: {
        email: "",
        password: "",
      },
      loading: false,
      recoveryPassoword: false,
    };
  },
  methods: {
    async submit() {
      if (!(await this.$refs.form.validate()).valid) return;
      this.loading = true;

      setTimeout(async () => {
        try {
          await LogIn(this.form);
          this.$router.push("/");
        } catch (err) {
          console.error(err);
          const message =
            err?.response?.data?.message || "Verifique seus dados";
          this.$swal("Ops...", message, "error");
        }
        this.loading = false;
      }, 1000);
    },
  },
};
</script>

<style></style>
