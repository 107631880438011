import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import store from "./store";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "./assets/scss/main.scss";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { loading } from "./plugins/loading";

import { Money3Directive } from "v-money3";
import VueTheMask from "vue-the-mask";

loadFonts();

export const app = createApp(App)
  .use(store)
  .use(vuetify)
  .use(loading)
  .use(router)
  .use(VueTheMask);

// app.use(() => (app.config.globalProperties.$user = { name: "Patrick" }));
app.use(VueSweetalert2);

app.use(Toast);
app.directive("money3", Money3Directive);
app.mount("#app");
