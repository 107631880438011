// Styles
import "vuetify/styles";
import { h } from "vue";

// Vuetify
import { createVuetify, IconProps } from "vuetify";

export default createVuetify({
  icons: {
    defaultSet: "ph",
    sets: {
      ph: {
        component: (props: IconProps) => h("i", { class: props.icon }),
      },
    },
    aliases: {
      clear: "ph-x",
      cancel: "ph-x",
      file: "ph-file",
      radioOff: "ph-circle",
      radioOn: "ph-check-circle",
    },
  },
  theme: {
    defaultTheme: "default",
    themes: {
      default: {
        colors: {
          primary: "#092136",
        },
      },
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
