import store from "@/store";
import { App, Plugin } from "vue";

export const loading: Plugin = {
  install: (app: App) => {
    app.config.globalProperties.$vloading = {
      show: () => {
        store.commit("setLoading", true);
      },
      hide: () => {
        store.commit("setLoading", false);
      },
    };
  },
};
