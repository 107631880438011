import { getMe } from "@/services/auth";
import store from "@/store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const AuthMiddleware = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (!localStorage.tk || !localStorage.lastTkDate) return next("/login");
  if (Date.now() > localStorage.lastTkDate + 172800000) return next("/login");

  try {
    if (!store.state.auth.user?.name) {
      await getMe();
      store.dispatch("live/get");
    }
  } catch {
    return next("/login");
  }

  if (store.state.auth.user) return next();
  return next("/login");
};
