<template>
  <div>
    <v-form @submit.prevent="submit" class="pa-4" ref="form" v-if="!loading">
      <p>
        A tabela abaixo exibe os atos praticados carregados no sistema. Informe
        nos campos de textos, o valor e a data do recebimento.
      </p>

      <div class="legend d-flex">
        <div>
          <div class="legend-icon readonly" />
          <span>Apenas leitura</span>
        </div>
        <div>
          <div class="legend-icon diff" />
          <span>Resultado</span>
        </div>
        <div>
          <div class="legend-icon editable" />
          <span>Entrada</span>
        </div>
        <div>
          <div class="legend-icon editable error" />
          <span>Entrada inválida</span>
        </div>
        <div>
          <div class="legend-icon editable ok" />
          <span>Entrada válida</span>
        </div>
      </div>
      <div style="overflow-x: auto">
        <table style="white-space: nowrap">
          <tr class="top">
            <th colspan="3">Ato</th>
            <th class="empty"></th>
            <th class="empty"></th>
            <th class="empty"></th>
            <th colspan="100">Recebimento</th>
          </tr>
          <tr>
            <th>Protocolo</th>
            <th>Valor</th>
            <th>Dt. Ato</th>
            <th class="empty"></th>
            <th>Diff</th>
            <th class="empty"></th>
            <th>Vl. recebido</th>
            <th>Dt. Recebimento</th>
            <th>Banco</th>
            <th>Conta</th>
            <th>Justificativa</th>
          </tr>
          <tr v-for="(item, i) in lot" :key="i">
            <td class="readonly">{{ item.protocol }}</td>
            <td class="readonly">{{ format.brl(item.amount / 100) }}</td>
            <td class="readonly">{{ format.date(item.date) }}</td>
            <td class="empty" />
            <td class="diff">
              {{ format.brl(calcDiff(i) / 100) }}
            </td>
            <td class="empty" />
            <td
              class="editable"
              :class="{ ok: relations[i] >= 0, error: relations[i] == -1 }"
            >
              <input v-model="lotData[i].amount" v-money3="money" />
            </td>
            <td
              class="editable"
              :class="{ ok: relations[i] >= 0, error: relations[i] == -1 }"
            >
              <!-- DATA -->
              <input v-model="lotData[i].date" v-mask="'##/##/####'" />
            </td>
            <td class="readonly">
              {{ bankByIndex[i] ? bankByIndex[i].name : "" }}
            </td>
            <td class="readonly">
              {{
                bankByIndex[i]
                  ? bankByIndex[i].agency + " / " + bankByIndex[i].number
                  : ""
              }}
            </td>
            <td class="editable">
              <input v-model="lotData[i].reason" />
            </td>
          </tr>
        </table>
      </div>

      <div class="mt-16 d-flex">
        <v-btn
          color="primary"
          size="large"
          @click="$emit('prev')"
          variant="text"
        >
          <v-icon>ph-arrow-left</v-icon>
          <span>Voltar</span>
        </v-btn>
        <v-spacer />
        <v-btn color="primary" size="large" type="submit">
          <span>Finalizar conciliação</span>
          <v-icon>ph-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-form>
    <span v-else>carregando...</span>
  </div>
</template>

<script>
import { FormBindind } from "../../mixins/form";
import { rules } from "../../utils/rules";
import { banks } from "../../.dev/mock/bank";
import * as format from "../../utils/format.util";
import { api } from "@/services/api";
import moment from "moment";

export default {
  name: "FinancialCloserStep3",
  mixins: [FormBindind],
  data() {
    return {
      format,
      auditStep: 0,
      rules,
      form: {
        atos: "",
        banks,
      },
      lotData: {},
      lot: [],
      banks: [],

      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
      loading: true,
    };
  },
  async created() {
    await this.get();
    this.loading = false;
    for (let i = 0; i < this.lot.length; i++) {
      this.lotData[i] = { date: "", amount: "" };
    }
  },
  methods: {
    async submit() {
      if (!confirm("As linhas em vermelho não serão registradas.")) return;

      const payload = [];

      const data = Object.values(this.relations);

      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (this.lot[i] && this.banks[item])
          payload.push({
            lot: this.lot[i]._id,
            bankTransaction: this.banks[item]._id,
          });
      }

      this.$vloading.show();
      await api.post("bank/save", payload);
      this.$vloading.hide();
      alert("Finalizado");
      window.reload();
    },
    prevBank() {
      this.auditStep--;
    },
    finish() {
      this.auditStep++;
    },
    fixValue(v) {
      return Math.round(`${v}`.replace(/,/g, ".") * 100);
    },
    calcDiff(i) {
      const lotAmount = this.fixBrl(
        this.lotData[i] ? this.lotData[i].amount : "0"
      );
      if (lotAmount && this.lot[i].amount) {
        return lotAmount - this.lot[i].amount;
      } else return 0;
    },
    fixBrl(v) {
      return Math.round(v.replace(/[^0-9]/g, "") * 1);
    },
    fixDate(v) {
      return new Date(v.split("/").reverse().join("-"));
    },
    async get() {
      return Promise.all([
        api.get("bank/waiting").then(({ data }) => (this.banks = data)),
        api.get("bank/lot/waiting").then(({ data }) => (this.lot = data)),
      ]);
    },
  },
  computed: {
    auditData() {
      return this.form.banks.map((bank) => {
        const rows =
          bank.ofx_result.OFX?.BANKMSGSRSV1?.BANKTRANLIST?.STMTTRN || [];

        bank.ieptb_transactions = rows
          .filter(
            (a) =>
              a.MEMO.toUpperCase().indexOf("IEPTB") >= 0 &&
              a.TRNTYPE === "CREDIT"
          )
          .map((a) => ({
            amount: this.fixValue(a.TRNAMT),
            date: new Date(
              [
                a.DTPOSTED.substring(0, 4),
                a.DTPOSTED.substring(6, 8),
                a.DTPOSTED.substring(6, 8),
              ].join("-")
            ),
            description: a.MEMO,
            status: "WAITING",
          }));

        return bank;
      });
    },
    relations() {
      const relations = [];

      const lotData = Object.values(this.lotData);

      for (let i = 0; i < lotData.length; i++) {
        const data = lotData[i];

        const selecteds = Object.values(relations);
        const selected = this.banks.findIndex((a) => {
          return (
            moment(new Date(a.date)).startOf("day").toDate().getTime() ===
              moment(this.fixDate(data.date))
                .startOf("day")
                .toDate()
                .getTime() && a.amount == this.fixBrl(data.amount)
          );
        });

        if (selecteds.includes(selected)) relations[i] = -1;
        else relations[i] = selected;
      }

      return relations;
    },
    bankByIndex() {
      const bank = {};
      for (let i = 0; i < this.relations.length; i++) {
        const bankIndex = this.relations[i];
        if (bankIndex >= 0) bank[i] = this.banks[bankIndex].bank;
      }

      return bank;
    },
  },
};
</script>

<style lang="scss">
td {
  padding: 0 5px;
  min-width: 100px;
  &.readonly {
    background: #0012bf;
    color: #fff;
  }
  &.diff {
    background: #000;
    color: #fff;
  }
  &.empty {
    color: #fff;
    width: 20px;
    min-width: 20px;
  }
  &.editable {
    background: #cbcdad;
    color: #000;
    padding: 0;
    input {
      width: 100%;
      height: 100%;
      padding: 0 5px;
    }
  }
  &.editable.error {
    background: #cdadad;
  }
  &.editable.ok {
    background: #adcdb0;
  }
}
th:not(.empty) {
  background: #ddd;
}
.top th {
  background: rgb(188, 188, 188);
}
th.empty {
  background: transparent;
}

.legend {
  display: flex;
  align-items: center;
  margin: 10px 0;

  > div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .legend-icon {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      &.readonly {
        background: #0012bf;
      }
      &.diff {
        background: #000;
      }
      &.editable {
        background: #cbcdad;
      }
      &.editable.error {
        background: #cdadad;
      }
      &.editable.ok {
        background: #adcdb0;
      }
    }
  }
}
</style>
