import { api } from "./api";
import { IBank } from "../interfaces/bank.interface";
import { getFileContent } from "./file";

export const ofx2Json = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const { data } = await api.post("/file/ofx", formData);
  return data.result;
  // return csvParse(content);
};

export const loadOfx = async (file: File, bank: IBank) => {
  const payload = {
    ofx: await getFileContent(file),
    bank,
    filename: file.name,
  };

  return await api.post("/bank/transaction", payload);
};
