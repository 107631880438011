<template>
  <div>
    <div class="mb-6">
      <h1>Carga de Atos Praticados</h1>
      <p>
        Suba um arquivo com a extensão .xls, representando os ultimos 5 dias
        (D-5 ~ D-1)
      </p>
    </div>

    <upload-doc
      v-model="atos"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    />
    <div class="d-flex justify-end">
      <v-btn variant="flat" color="primary" :disabled="!valid" @click="submit">
        Carregar
      </v-btn>
    </div>
  </div>
</template>

<script>
import UploadDoc from "@/components/UploadDoc.vue";
import { xls2Json } from "../../services/csv";
import { loadLot } from "@/services/lot";
import moment from "moment";

export default {
  name: "AtosView",
  data: () => ({ atos: null, atosResult: [], valid: false }),
  components: { UploadDoc },
  methods: {
    async handleChangeFile() {
      this.loading = true;
      const result = await xls2Json(this.atos);
      this.atosResult = result;
      this.validateFile();
    },
    validateFile() {
      const requiredHeader = [
        "Protocolo",
        "Data",
        "Data da Situação",
        "Situação",
        "Total",
        "TotalCaixa",
        "Origem",
        "Apresentante",
        "Banco",
        "Agencia",
        "Conta",
        // "Data do Pagamento (Boleto)",
        "Selo",
      ];
      console.log(
        this.atosResult,
        requiredHeader.find((k) => this.atosResult[0][k] === undefined)
      );
      this.valid = !requiredHeader.find(
        (k) => this.atosResult[0][k] === undefined
      );
      console.log(" this.valid", this.valid);
      if (!this.valid)
        this.$swal(
          "Formato de arquivo inválido",
          `Seu arquivo deve conter: ${requiredHeader.join(", ")}`,
          "error"
        );
    },
    fixValue(v) {
      return Math.round(`${v}`.replace(/,/g, ".") * 100);
    },
    async submit() {
      window.data = this.atosResult;
      if (this.atosResult) return;
      const rows = Object.values(
        this.atosResult.filter((a) => a.Protocolo && a.Conta)
        // .reduce((res, item) => {
        //   if (res[item.Protocolo])
        //     res[item.Protocolo].amount += this.fixValue(item.Total);
        //   else
        //     res[item.Protocolo] = {
        //       ...item,
        //       amount: this.fixValue(item.Total),
        //     };
        //   return res;
        // }, {})
      ).map((a) => ({
        protocol: a.Protocolo,
        date: moment(a["Data da Situação"] + " 00:00:00").format(
          "YYYY-MM-DD 00:00:00"
        ), //new Date(a.Data.split("/").reverse().join("-")),
        description: "#",
        raw: a,
        origin: a.Origem,
        uuid: (a.Protocolo + a.Data).replace(/[^0-9]/g, ""),
        amount: this.fixValue(a.TotalCaixa),
        bank: { name: a.Banco, number: a.Conta, agency: a.Agencia },
      }));

      try {
        this.$vloading.show();
        await loadLot(this.atos.name, rows);
        this.$swal(
          "Concluído",
          "Dados foram imortados para o banco de dados!",
          "success"
        );
        this.$vloading.hide();
      } catch (err) {
        this.$swal("Ops...", err, "error");
        this.$vloading.hide();
      }
    },
  },
  watch: {
    atos(v) {
      console.log(v);
      if (v) this.handleChangeFile();
    },
  },
};
</script>

<style></style>
