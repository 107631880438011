<template>
  <div>
    <div class="mb-6">
      <h1>Carga de dados bancários</h1>
      <p>
        Suba um arquivo com a extensão .ofx, representando os ultimos 5 dias de
        movimentação (D-5 ~ D-1)
      </p>
    </div>
    <div class="d-flex">
      <v-select
        variant="outlined"
        label="Banco"
        :items="
          banks.map((a) => ({
            title: banksName[a] ? `${a} - ${banksName[a]}` : a,
            value: a,
          }))
        "
        v-model="selectedBank"
        style="max-width: 200px"
      />
      <v-select
        class="ml-4"
        variant="outlined"
        label="Agência / Contas"
        :disabled="!selectedBank"
        :items="
          (accounts[selectedBank] || []).map((a, i) => ({
            title: `${a.agency} / ${a.number}`,
            value: i,
          }))
        "
        v-model="selectedAccount"
        style="max-width: 200px"
      />
    </div>
    <upload-doc v-model="ofxFile" accept=".ofx,.OFX" ref="upload" />
    <div class="d-flex justify-end my-5">
      <v-btn
        variant="flat"
        color="primary"
        :disabled="!ofxFile || !selectedBank || !(selectedAccount >= 0)"
        @click="submit"
      >
        Carregar
      </v-btn>
    </div>
  </div>
</template>

<script>
import UploadDoc from "@/components/UploadDoc.vue";
import { getAllBanks } from "../../services/bank";
import { loadOfx } from "@/services/ofx";

export default {
  components: { UploadDoc },
  name: "OfxUpload",
  data: () => ({
    selectedBank: "",
    selectedAccount: "",
    banks: [],
    accounts: {},
    banksName: {
      341: "Itaú",
      237: "Bradesco",
    },
    uploads: [],
    ofxFile: null,
  }),
  created() {
    getAllBanks().then((a) => {
      for (let i = 0; i < a.length; i++) {
        const item = a[i];
        if (!this.accounts[item.name]) {
          this.accounts[item.name] = [];
          this.banks.push(item.name);
        }
        this.accounts[item.name].push(item);
      }
    });
  },
  watch: {
    selectedBank() {
      this.selectedAccount = "";
    },
  },
  methods: {
    submit() {
      const bankAccount =
        this.accounts[this.selectedBank][this.selectedAccount];
      loadOfx(this.ofxFile, {
        _id: "string",
        ...bankAccount,
      }).then(() => {
        this.$swal(
          "Sucesso!",
          "Os dados foram carregados para o banco de dados!",
          "success"
        );
        this.reset();
      });
    },
    reset() {
      this.selectedBank = "";
      this.selectedAccount = "";
      this.ofxFile = null;
      this.$refs.upload.reset();
    },
  },
};
</script>

<style></style>
