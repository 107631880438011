<template>
  <div>
    <table style="white-space: nowrap" v-if="data">
      <tr class="top">
        <th colspan="2">Ato</th>
        <th class="empty"></th>
        <th class="empty"></th>
        <th class="empty"></th>
        <th colspan="100">Recebimento</th>
      </tr>
      <tr>
        <th>Valor</th>
        <th>Dt. Ato</th>
        <th class="empty"></th>
        <th>Diff</th>
        <th class="empty"></th>
        <th>Vl. recebido</th>
        <th>Dt. Recebimento</th>
        <th>Banco</th>
        <th>Conta</th>
        <th>Justificativa</th>
      </tr>
      <template v-for="(dateBank, dateAto) in dates" :key="dateAto">
        <tr v-for="(_, bankNumber) in data[dateAto]" :key="bankNumber">
          <td class="readonly">
            {{ format.brl(getAmount("atos", dateAto, bankNumber) / 100) }}
          </td>
          <td class="readonly">
            {{ format.date(dateAto) }}
          </td>
          <td class="empty" />

          <td
            class="diff"
            :class="'justificativa_' + getJustificativa(dateAto, bankNumber)"
          >
            {{
              format.brl(
                Math.abs(
                  getAmount("atos", dateAto, bankNumber) -
                    getAmount("bank", dateBank, bankNumber)
                ) / 100
              )
            }}
          </td>
          <td class="empty" />

          <td class="readonly">
            {{ format.brl(getAmount("bank", dateBank, bankNumber) / 100) }}
          </td>
          <td class="readonly">
            {{ format.date(dateBank) }}
          </td>
          <td class="readonly">{{ bankNumber }}</td>
          <td class="readonly">{{ bankNumber }}</td>
          <td
            :class="'justificativa_' + getJustificativa(dateAto, bankNumber)"
            @click="addJustificativa(dateAto, bankNumber)"
          >
            <span>{{ getJustificativa(dateAto, bankNumber) }}</span>
            <v-icon
              size="17"
              class="ml-1"
              style="cursor: pointer"
              v-if="canEdit(dateAto, bankNumber)"
            >
              ph-pen
            </v-icon>
          </td>
          <!-- <td
          class="editable"
          :class="{ ok: relations[i] >= 0, error: relations[i] == -1 }"
        >
          <input v-model="lotData[i].amount" v-money3="money" />
        </td>
        <td
          class="editable"
          :class="{ ok: relations[i] >= 0, error: relations[i] == -1 }"
        >
          <input v-model="lotData[i].date" v-mask="'##/##/####'" />
        </td>
        <td class="readonly">
          {{ bankByIndex[i] ? bankByIndex[i].name : "" }}
        </td>
        <td class="readonly">
          {{
            bankByIndex[i]
              ? bankByIndex[i].agency + " / " + bankByIndex[i].number
              : ""
          }}
        </td>
        <td class="editable">
          <input v-model="lotData[i].reason" />
        </td>
        --></tr>
      </template>
    </table>
    <div class="my-6">
      <hr />
      <h2>Feriados</h2>
      <p v-for="(name, date) in holidays" :key="date">
        {{ format.date(date) }} - {{ name }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as format from "../../utils/format.util";
import { createAudit, getAudit } from "../../services/audit";
import { getHolidaysApi } from "../../services/holidays";

export default {
  name: "ArqBancView",
  data: () => ({
    holidays: {},
    data: null,

    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    format,
  }),
  computed: {
    dates() {
      const startDate = moment(new Date(Date.now() - 1000 * 60 * 60 * 24 * 30));
      const endDate = moment(new Date());

      const result = [];

      let currentDate = moment(startDate).subtract(1, "day");
      while (currentDate <= endDate) {
        currentDate.add(1, "day");
        if ([0, 6].includes(currentDate.day())) continue;
        const dateString = currentDate.format("YYYY-MM-DD");
        if (this.holidays[dateString]) continue;
        result.push(currentDate.format("YYYY-MM-DD"));
      }

      return Object.fromEntries(
        result
          .map((d, i) => [d, result[i + 1]])
          .slice(0, -1)
          .reverse()
      );
    },
  },
  created() {
    if (!this.data) getAudit().then((a) => (this.data = a));

    this.getHolidays(new Date().getFullYear());

    if (new Date().getMonth() === 11)
      this.getHolidays(new Date().getFullYear() + 1);
    if (new Date().getMonth() === 0)
      this.getHolidays(new Date().getFullYear() - 1);
  },
  methods: {
    getAmount(type, date, bank) {
      return ((this.data[date] || {})[bank] || {})[type] || 0;
    },
    getJustificativa(date, bank) {
      const audit = ((this.data[date] || {})[bank] || {}).audit;

      if (!audit) return "pendente";
      else if (audit.masterApprove) return "aceito";
      else if (audit.status === "WAITING") return "enviado";
      else if (audit.status === "REFUSED") return "rejeitado";

      return "pendente";
    },
    canEdit(date, bank) {
      const status = this.getJustificativa(date, bank);
      return status !== "aceito";
    },
    addJustificativa(date, bank) {
      const current = (this.data[date] || {})[bank]?.audit?.description || "";

      this.$swal({
        title: "Digite a sua justificativa",
        html:
          '<input id="justificativa_input" class="swal2-input" value="' +
          current +
          '">',
        preConfirm: async () => {
          const value = window.justificativa_input.value || "(vazio)";
          this.$vloading.show();
          try {
            const data = await createAudit({
              description: value,
              amount: Math.abs(
                this.getAmount("atos", date, bank) -
                  this.getAmount("bank", date, bank)
              ),
              type: "arq",
              bank,
              date: new Date(`${date} 00:00:00.000`),
              auditData: {
                bank: this.getAmount("bank", this.dates[date], bank),
                atos: this.getAmount("atos", date, bank),
                bankDate: new Date(date + "T00:00:00"),
              },
            });

            this.data[date][bank].audit = data;

            this.$swal(
              "Enviado!",
              "Sua justificativa foi enviado ao tabelião!",
              "success"
            );
          } catch (err) {
            console.error(err);
            this.$swal(
              "Ops...",
              err?.response?.data?.message || "Ocorreu um erro",
              "error"
            );
          }
          this.$vloading.hide();
        },
      })
        .then(console.log)
        .catch(this.$swal.noop);
    },
    getHolidays(year) {
      getHolidaysApi(year).then((data) => Object.assign(this.holidays, data));
    },
  },
};
</script>

<style lang="scss">
td {
  padding: 0 5px;
  min-width: 100px;
  &.readonly {
    background: #0012bf;
    color: #fff;
  }
  &.diff {
    background: #000;
    color: #fff;
  }
  &.empty {
    color: #fff;
    width: 20px;
    min-width: 20px;
  }
  &.editable {
    background: #cbcdad;
    color: #000;
    padding: 0;
    input {
      width: 100%;
      height: 100%;
      padding: 0 5px;
    }
  }
  &.editable.error {
    background: #cdadad;
  }
  &.editable.ok {
    background: #adcdb0;
  }

  &.justificativa_enviado {
    background: rgb(255, 149, 0);
    color: white;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  &.justificativa_aceito {
    background: rgb(141, 197, 0);
    color: white;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  &.justificativa_rejeitado {
    background: rgb(255, 0, 0);
    color: white;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  &.justificativa_pendente {
    background: rgb(155, 142, 0);
    color: white;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    &.diff {
      background: black !important;
    }
  }
}
th:not(.empty) {
  background: #ddd;
}
.top th {
  background: rgb(188, 188, 188);
}
th.empty {
  background: transparent;
}

.legend {
  display: flex;
  align-items: center;
  margin: 10px 0;

  > div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .legend-icon {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      &.readonly {
        background: #0012bf;
      }
      &.diff {
        background: #000;
      }
      &.editable {
        background: #cbcdad;
      }
      &.editable.error {
        background: #cdadad;
      }
      &.editable.ok {
        background: #adcdb0;
      }
    }
  }
}
</style>
