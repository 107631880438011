import { createStore } from "vuex";
import { AuthState } from "./auth";
import { AuthStoreModule } from "./auth";
import { BankStoreModule } from "./banks";

export interface StoreData {
  loading: boolean;
  auth: AuthState;
}

export default createStore({
  state: {} as StoreData,
  getters: {},
  mutations: {
    setLoading: (state, payload) => (state.loading = !!payload),
  },
  actions: {},
  modules: { auth: AuthStoreModule, banks: BankStoreModule },
});
