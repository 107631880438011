import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      _createVNode(_component_v_overlay, {
        modelValue: _ctx.$store.state.loading,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$store.state.loading) = $event)),
        class: "d-flex align-center justify-center",
        absolute: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_progress_circular, {
            indeterminate: "",
            color: "primary darken-4"
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}