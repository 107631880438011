export const banks = [
  {
    name: "Banco Itáu",
    agency: "9278",
    number: "99734-2",
    ofx: "[object File]",
    ofx_result: {
      OFX: {
        SIGNONMSGSRSV1: {
          SONRS: {
            STATUS: { CODE: "0", SEVERITY: "INFO" },
            DTSERVER: "20220505100000[-03:EST]",
            LANGUAGE: "POR",
          },
        },
        BANKMSGSRSV1: {
          CURDEF: "BRL",
          BANKACCTFROM: {
            BANKID: "0341",
            ACCTID: "9278997342",
            ACCTTYPE: "CHECKING",
          },
          BANKTRANLIST: {
            DTSTART: "20220502100000[-03:EST]",
            DTEND: "20220505100000[-03:EST]",
            STMTTRN: [
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-871.20",
                FITID: "20220502001",
                CHECKNUM: "20220502001",
                MEMO: "SISPAG BOLETO BANCO 341",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-16117.20",
                FITID: "20220502002",
                CHECKNUM: "20220502002",
                MEMO: "SISPAG BOLETO BANCO 341",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-100.63",
                FITID: "20220502003",
                CHECKNUM: "20220502003",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-101.61",
                FITID: "20220502004",
                CHECKNUM: "20220502004",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-209.10",
                FITID: "20220502005",
                CHECKNUM: "20220502005",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-719.73",
                FITID: "20220502006",
                CHECKNUM: "20220502006",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-141.02",
                FITID: "20220502007",
                CHECKNUM: "20220502007",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-101.39",
                FITID: "20220502008",
                CHECKNUM: "20220502008",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-70.00",
                FITID: "20220502009",
                CHECKNUM: "20220502009",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-88.11",
                FITID: "20220502010",
                CHECKNUM: "20220502010",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-153.60",
                FITID: "20220502011",
                CHECKNUM: "20220502011",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-89.50",
                FITID: "20220502012",
                CHECKNUM: "20220502012",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-262.37",
                FITID: "20220502013",
                CHECKNUM: "20220502013",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-65.09",
                FITID: "20220502014",
                CHECKNUM: "20220502014",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-65.09",
                FITID: "20220502015",
                CHECKNUM: "20220502015",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-57.09",
                FITID: "20220502016",
                CHECKNUM: "20220502016",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-66.09",
                FITID: "20220502017",
                CHECKNUM: "20220502017",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-383.20",
                FITID: "20220502018",
                CHECKNUM: "20220502018",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-103.10",
                FITID: "20220502019",
                CHECKNUM: "20220502019",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-35.40",
                FITID: "20220502020",
                CHECKNUM: "20220502020",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-1506.41",
                FITID: "20220502021",
                CHECKNUM: "20220502021",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-232.14",
                FITID: "20220502022",
                CHECKNUM: "20220502022",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-51.27",
                FITID: "20220502023",
                CHECKNUM: "20220502023",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-564.92",
                FITID: "20220502024",
                CHECKNUM: "20220502024",
                MEMO: "SISPAG TRIB COD BARRAS",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-46.89",
                FITID: "20220502025",
                CHECKNUM: "20220502025",
                MEMO: "SISPAG TRIB COD BARRAS",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-244.43",
                FITID: "20220502026",
                CHECKNUM: "20220502026",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-80.04",
                FITID: "20220502027",
                CHECKNUM: "20220502027",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-1276.94",
                FITID: "20220502028",
                CHECKNUM: "20220502028",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-206.17",
                FITID: "20220502029",
                CHECKNUM: "20220502029",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-210.54",
                FITID: "20220502030",
                CHECKNUM: "20220502030",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-356.51",
                FITID: "20220502031",
                CHECKNUM: "20220502031",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-283.18",
                FITID: "20220502032",
                CHECKNUM: "20220502032",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-255.11",
                FITID: "20220502033",
                CHECKNUM: "20220502033",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-2608.23",
                FITID: "20220502034",
                CHECKNUM: "20220502034",
                MEMO: "SISPAG BOLET OUTR BCO",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-49398.69",
                FITID: "20220502035",
                CHECKNUM: "20220502035",
                MEMO: "SISPAG BOLET OUTR BCO",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-916.24",
                FITID: "20220502036",
                CHECKNUM: "20220502036",
                MEMO: "SISPAG BOLET OUTR BCO",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "-8.40",
                FITID: "20220502037",
                CHECKNUM: "20220502037",
                MEMO: "TAR/CUSTAS COBRANCA",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "258.56",
                FITID: "20220502038",
                CHECKNUM: "20220502038",
                MEMO: "TED 237.0099INST ESTUDOS",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "431.99",
                FITID: "20220502039",
                CHECKNUM: "20220502039",
                MEMO: "TED 237.0099INST ESTUDOS",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "47414.18",
                FITID: "20220502040",
                CHECKNUM: "20220502040",
                MEMO: "TED 237.1901JOAO BATISTA",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "722.26",
                FITID: "20220502041",
                CHECKNUM: "20220502041",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "102.35",
                FITID: "20220502042",
                CHECKNUM: "20220502042",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "1261.19",
                FITID: "20220502043",
                CHECKNUM: "20220502043",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "1040.19",
                FITID: "20220502044",
                CHECKNUM: "20220502044",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "522.84",
                FITID: "20220502045",
                CHECKNUM: "20220502045",
                MEMO: "PIX TRANSF bruno c02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "340.58",
                FITID: "20220502046",
                CHECKNUM: "20220502046",
                MEMO: "PIX TRANSF Andre W02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "424.24",
                FITID: "20220502047",
                CHECKNUM: "20220502047",
                MEMO: "PIX TRANSF ADRIANA02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "1780.78",
                FITID: "20220502048",
                CHECKNUM: "20220502048",
                MEMO: "PIX TRANSF ANGELA 02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "114.92",
                FITID: "20220502049",
                CHECKNUM: "20220502049",
                MEMO: "PIX TRANSF ARETHA 02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "33.55",
                FITID: "20220502050",
                CHECKNUM: "20220502050",
                MEMO: "PIX TRANSF BEATRIZ02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "389.45",
                FITID: "20220502051",
                CHECKNUM: "20220502051",
                MEMO: "PIX TRANSF CAROLIN02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "218.02",
                FITID: "20220502052",
                CHECKNUM: "20220502052",
                MEMO: "PIX TRANSF DANIELA02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "413.36",
                FITID: "20220502053",
                CHECKNUM: "20220502053",
                MEMO: "PIX TRANSF DELIANE02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "1481.28",
                FITID: "20220502054",
                CHECKNUM: "20220502054",
                MEMO: "PIX TRANSF EDSON A02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "115.90",
                FITID: "20220502055",
                CHECKNUM: "20220502055",
                MEMO: "PIX TRANSF ELIANE 02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "181.01",
                FITID: "20220502056",
                CHECKNUM: "20220502056",
                MEMO: "PIX TRANSF ELIZANG02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "127.30",
                FITID: "20220502057",
                CHECKNUM: "20220502057",
                MEMO: "PIX TRANSF ERICK F02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "479.77",
                FITID: "20220502058",
                CHECKNUM: "20220502058",
                MEMO: "PIX TRANSF Fernand02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "0.36",
                FITID: "20220502059",
                CHECKNUM: "20220502059",
                MEMO: "PIX TRANSF GESMAR 02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "180.00",
                FITID: "20220502060",
                CHECKNUM: "20220502060",
                MEMO: "PIX TRANSF GESMAR 02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "1404.32",
                FITID: "20220502061",
                CHECKNUM: "20220502061",
                MEMO: "PIX TRANSF GUILHER02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "819.70",
                FITID: "20220502062",
                CHECKNUM: "20220502062",
                MEMO: "PIX TRANSF IVANIA 02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "286.22",
                FITID: "20220502063",
                CHECKNUM: "20220502063",
                MEMO: "PIX TRANSF Jaina P02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "524.01",
                FITID: "20220502064",
                CHECKNUM: "20220502064",
                MEMO: "PIX TRANSF Jose Ed02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "475.38",
                FITID: "20220502065",
                CHECKNUM: "20220502065",
                MEMO: "PIX TRANSF KEILA J02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "209.00",
                FITID: "20220502066",
                CHECKNUM: "20220502066",
                MEMO: "PIX TRANSF KLERCIO02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "418.18",
                FITID: "20220502067",
                CHECKNUM: "20220502067",
                MEMO: "PIX TRANSF LILIANE02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "485.75",
                FITID: "20220502068",
                CHECKNUM: "20220502068",
                MEMO: "PIX TRANSF MARCIO 02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "539.64",
                FITID: "20220502069",
                CHECKNUM: "20220502069",
                MEMO: "PIX TRANSF MARIA C02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "510.99",
                FITID: "20220502070",
                CHECKNUM: "20220502070",
                MEMO: "PIX TRANSF MAYCON 02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "216.53",
                FITID: "20220502071",
                CHECKNUM: "20220502071",
                MEMO: "PIX TRANSF RAFAEL 02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "360.02",
                FITID: "20220502072",
                CHECKNUM: "20220502072",
                MEMO: "PIX TRANSF SIMONE 02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "255.94",
                FITID: "20220502073",
                CHECKNUM: "20220502073",
                MEMO: "PIX TRANSF TELNE C02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "760.60",
                FITID: "20220502074",
                CHECKNUM: "20220502074",
                MEMO: "PIX TRANSF TELNE C02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "154.61",
                FITID: "20220502075",
                CHECKNUM: "20220502075",
                MEMO: "PIX TRANSF UNEVIST02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "172.01",
                FITID: "20220502076",
                CHECKNUM: "20220502076",
                MEMO: "PIX TRANSF VALDIVI02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "1283.62",
                FITID: "20220502077",
                CHECKNUM: "20220502077",
                MEMO: "PIX TRANSF VANDERL02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "1411.99",
                FITID: "20220502078",
                CHECKNUM: "20220502078",
                MEMO: "PIX TRANSF VANDERL02/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "951090.29",
                FITID: "20220502079",
                CHECKNUM: "20220502079",
                MEMO: "RESGATE CDB",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220502100000[-03:EST]",
                TRNAMT: "6024.42",
                FITID: "20220502080",
                CHECKNUM: "20220502080",
                MEMO: "MOV TIT COB DISP 02/05S",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-205.64",
                FITID: "20220503001",
                CHECKNUM: "20220503001",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-35.85",
                FITID: "20220503002",
                CHECKNUM: "20220503002",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-51.09",
                FITID: "20220503003",
                CHECKNUM: "20220503003",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-102.59",
                FITID: "20220503004",
                CHECKNUM: "20220503004",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-55.70",
                FITID: "20220503005",
                CHECKNUM: "20220503005",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-321.31",
                FITID: "20220503006",
                CHECKNUM: "20220503006",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-173.28",
                FITID: "20220503007",
                CHECKNUM: "20220503007",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-57.38",
                FITID: "20220503008",
                CHECKNUM: "20220503008",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-67.80",
                FITID: "20220503009",
                CHECKNUM: "20220503009",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-326645.40",
                FITID: "20220503010",
                CHECKNUM: "20220503010",
                MEMO: "SISPAG PIX TRANSFERENCI",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-563.91",
                FITID: "20220503011",
                CHECKNUM: "20220503011",
                MEMO: "SISPAG TRIB COD BARRAS",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-608.97",
                FITID: "20220503012",
                CHECKNUM: "20220503012",
                MEMO: "SISPAG TRIB COD BARRAS",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-3631.54",
                FITID: "20220503013",
                CHECKNUM: "20220503013",
                MEMO: "SISPAG TRIB COD BARRAS",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-80.05",
                FITID: "20220503014",
                CHECKNUM: "20220503014",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-132.18",
                FITID: "20220503015",
                CHECKNUM: "20220503015",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-206.17",
                FITID: "20220503016",
                CHECKNUM: "20220503016",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-206.17",
                FITID: "20220503017",
                CHECKNUM: "20220503017",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-206.17",
                FITID: "20220503018",
                CHECKNUM: "20220503018",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-154.52",
                FITID: "20220503019",
                CHECKNUM: "20220503019",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-143.51",
                FITID: "20220503020",
                CHECKNUM: "20220503020",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-206.17",
                FITID: "20220503021",
                CHECKNUM: "20220503021",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-148.44",
                FITID: "20220503022",
                CHECKNUM: "20220503022",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-268.96",
                FITID: "20220503023",
                CHECKNUM: "20220503023",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-220.77",
                FITID: "20220503024",
                CHECKNUM: "20220503024",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-145.99",
                FITID: "20220503025",
                CHECKNUM: "20220503025",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-280.61",
                FITID: "20220503026",
                CHECKNUM: "20220503026",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-284.19",
                FITID: "20220503027",
                CHECKNUM: "20220503027",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-206.17",
                FITID: "20220503028",
                CHECKNUM: "20220503028",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-1314.26",
                FITID: "20220503029",
                CHECKNUM: "20220503029",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-203.07",
                FITID: "20220503030",
                CHECKNUM: "20220503030",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-565.16",
                FITID: "20220503031",
                CHECKNUM: "20220503031",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-7863.08",
                FITID: "20220503032",
                CHECKNUM: "20220503032",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-206.17",
                FITID: "20220503033",
                CHECKNUM: "20220503033",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-1.13",
                FITID: "20220503034",
                CHECKNUM: "20220503034",
                MEMO: "IOF",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-3045.44",
                FITID: "20220503035",
                CHECKNUM: "20220503035",
                MEMO: "SISPAG BOLET OUTR BCO",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-933369.21",
                FITID: "000020",
                CHECKNUM: "000020",
                MEMO: "CH COMPENSADO 104 000020",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-56.40",
                FITID: "20220503036",
                CHECKNUM: "20220503036",
                MEMO: "TAR/CUSTAS COBRANCA",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "475.92",
                FITID: "20220503037",
                CHECKNUM: "20220503037",
                MEMO: "SISPAG ANTONIO ILIDIO B",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "1756.41",
                FITID: "20220503038",
                CHECKNUM: "20220503038",
                MEMO: "SISPAG EFICAZ LIMPEZA C",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "4584.75",
                FITID: "20220503039",
                CHECKNUM: "20220503039",
                MEMO: "TED 077.0001BARBARA OH M",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "246.97",
                FITID: "20220503040",
                CHECKNUM: "20220503040",
                MEMO: "TED 237.0099INST ESTUDOS",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "325.28",
                FITID: "20220503041",
                CHECKNUM: "20220503041",
                MEMO: "TED 237.0099INST ESTUDOS",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "182.43",
                FITID: "20220503042",
                CHECKNUM: "20220503042",
                MEMO: "TED 237.0099INST ESTUDOS",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "326645.40",
                FITID: "20220503043",
                CHECKNUM: "20220503043",
                MEMO: "TED 237.19011 TABEL PROT",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "2664.07",
                FITID: "20220503044",
                CHECKNUM: "20220503044",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "1155.82",
                FITID: "20220503045",
                CHECKNUM: "20220503045",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "1185.51",
                FITID: "20220503046",
                CHECKNUM: "20220503046",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "52.58",
                FITID: "20220503047",
                CHECKNUM: "20220503047",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "284.33",
                FITID: "20220503048",
                CHECKNUM: "20220503048",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "820.79",
                FITID: "20220503049",
                CHECKNUM: "20220503049",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "52.58",
                FITID: "20220503050",
                CHECKNUM: "20220503050",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "33.55",
                FITID: "20220503051",
                CHECKNUM: "20220503051",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "270.08",
                FITID: "20220503052",
                CHECKNUM: "20220503052",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "826.66",
                FITID: "20220503053",
                CHECKNUM: "20220503053",
                MEMO: "PIX TRANSF ADELEON03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "238.71",
                FITID: "20220503054",
                CHECKNUM: "20220503054",
                MEMO: "PIX TRANSF ADRIELA03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "300.66",
                FITID: "20220503055",
                CHECKNUM: "20220503055",
                MEMO: "PIX TRANSF ALEXAND03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "319.39",
                FITID: "20220503056",
                CHECKNUM: "20220503056",
                MEMO: "PIX TRANSF ANDERSO03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "62.17",
                FITID: "20220503057",
                CHECKNUM: "20220503057",
                MEMO: "PIX TRANSF ANTONIO03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "5740.17",
                FITID: "20220503058",
                CHECKNUM: "20220503058",
                MEMO: "PIX TRANSF CONPEV 03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "2527.15",
                FITID: "20220503059",
                CHECKNUM: "20220503059",
                MEMO: "PIX TRANSF CONSTRU03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "313.53",
                FITID: "20220503060",
                CHECKNUM: "20220503060",
                MEMO: "PIX TRANSF Daiane 03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "10457.26",
                FITID: "20220503061",
                CHECKNUM: "20220503061",
                MEMO: "PIX TRANSF FIVE EL03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "167.50",
                FITID: "20220503062",
                CHECKNUM: "20220503062",
                MEMO: "PIX TRANSF GILDEON03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "640.98",
                FITID: "20220503063",
                CHECKNUM: "20220503063",
                MEMO: "PIX TRANSF JESSICA03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "878.16",
                FITID: "20220503064",
                CHECKNUM: "20220503064",
                MEMO: "PIX TRANSF JESSICA03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "163.80",
                FITID: "20220503065",
                CHECKNUM: "20220503065",
                MEMO: "PIX TRANSF JUSSARA03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "1564.24",
                FITID: "20220503066",
                CHECKNUM: "20220503066",
                MEMO: "PIX TRANSF Leandro03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "333.02",
                FITID: "20220503067",
                CHECKNUM: "20220503067",
                MEMO: "PIX TRANSF LUCIANA03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "193.73",
                FITID: "20220503068",
                CHECKNUM: "20220503068",
                MEMO: "PIX TRANSF LUDY KN03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "114.92",
                FITID: "20220503069",
                CHECKNUM: "20220503069",
                MEMO: "PIX TRANSF MOISES 03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "86.13",
                FITID: "20220503070",
                CHECKNUM: "20220503070",
                MEMO: "PIX TRANSF NADIANA03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "7300.37",
                FITID: "20220503071",
                CHECKNUM: "20220503071",
                MEMO: "PIX TRANSF NET USE03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "5000.00",
                FITID: "20220503072",
                CHECKNUM: "20220503072",
                MEMO: "PIX TRANSF ROBERTO03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "52.58",
                FITID: "20220503073",
                CHECKNUM: "20220503073",
                MEMO: "PIX TRANSF Suprema03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "114.92",
                FITID: "20220503074",
                CHECKNUM: "20220503074",
                MEMO: "PIX TRANSF THIAGO 03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "795.79",
                FITID: "20220503075",
                CHECKNUM: "20220503075",
                MEMO: "PIX TRANSF WANDERL03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "696.04",
                FITID: "20220503076",
                CHECKNUM: "20220503076",
                MEMO: "PIX TRANSF WESLEY 03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "260.91",
                FITID: "20220503077",
                CHECKNUM: "20220503077",
                MEMO: "PIX TRANSF WESLEY 03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "247.22",
                FITID: "20220503078",
                CHECKNUM: "20220503078",
                MEMO: "PIX TRANSF WONDERS03/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "173568.21",
                FITID: "20220503079",
                CHECKNUM: "20220503079",
                MEMO: "MOV TIT COB DISP 03/05S",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-12.40",
                FITID: "20220503080",
                CHECKNUM: "20220503080",
                MEMO: "TAR DEPOSITO CHQ",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-67.57",
                FITID: "20220503081",
                CHECKNUM: "20220503081",
                MEMO: "TAR ADAPT 1 EXCED 04/22",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "-0.50",
                FITID: "20220503082",
                CHECKNUM: "20220503082",
                MEMO: "TAR PIX QR CODE LIQUIDA",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220503100000[-03:EST]",
                TRNAMT: "0.24",
                FITID: "20220503083",
                CHECKNUM: "20220503083",
                MEMO: "REND PAGO APLIC AUT MAIS",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-409.03",
                FITID: "20220504001",
                CHECKNUM: "20220504001",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-256.09",
                FITID: "20220504002",
                CHECKNUM: "20220504002",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-73.37",
                FITID: "20220504003",
                CHECKNUM: "20220504003",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-917.75",
                FITID: "20220504004",
                CHECKNUM: "20220504004",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-150.26",
                FITID: "20220504005",
                CHECKNUM: "20220504005",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-68.83",
                FITID: "20220504006",
                CHECKNUM: "20220504006",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-66.73",
                FITID: "20220504007",
                CHECKNUM: "20220504007",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-16.41",
                FITID: "20220504008",
                CHECKNUM: "20220504008",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-16.61",
                FITID: "20220504009",
                CHECKNUM: "20220504009",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-16.50",
                FITID: "20220504010",
                CHECKNUM: "20220504010",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-43.92",
                FITID: "20220504011",
                CHECKNUM: "20220504011",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-44.91",
                FITID: "20220504012",
                CHECKNUM: "20220504012",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-90.46",
                FITID: "20220504013",
                CHECKNUM: "20220504013",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-838.50",
                FITID: "20220504014",
                CHECKNUM: "20220504014",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-259.49",
                FITID: "20220504015",
                CHECKNUM: "20220504015",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-45.23",
                FITID: "20220504016",
                CHECKNUM: "20220504016",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-101.13",
                FITID: "20220504017",
                CHECKNUM: "20220504017",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-15.18",
                FITID: "20220504018",
                CHECKNUM: "20220504018",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-71.17",
                FITID: "20220504019",
                CHECKNUM: "20220504019",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-203.63",
                FITID: "20220504020",
                CHECKNUM: "20220504020",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-357.52",
                FITID: "20220504021",
                CHECKNUM: "20220504021",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-149.24",
                FITID: "20220504022",
                CHECKNUM: "20220504022",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-71.03",
                FITID: "20220504023",
                CHECKNUM: "20220504023",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-101.52",
                FITID: "20220504024",
                CHECKNUM: "20220504024",
                MEMO: "SISPAG CONCESSIONARIA",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-21296.00",
                FITID: "20220504025",
                CHECKNUM: "20220504025",
                MEMO: "SISPAG PIX TRANSFERENCI",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-41.36",
                FITID: "20220504026",
                CHECKNUM: "20220504026",
                MEMO: "SISPAG TRIB COD BARRAS",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-66.14",
                FITID: "20220504027",
                CHECKNUM: "20220504027",
                MEMO: "SISPAG TRIB COD BARRAS",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-487.43",
                FITID: "20220504028",
                CHECKNUM: "20220504028",
                MEMO: "SISPAG TRIB COD BARRAS",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-508.01",
                FITID: "20220504029",
                CHECKNUM: "20220504029",
                MEMO: "SISPAG TRIB COD BARRAS",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-503.45",
                FITID: "20220504030",
                CHECKNUM: "20220504030",
                MEMO: "SISPAG TRIB COD BARRAS",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-269.98",
                FITID: "20220504031",
                CHECKNUM: "20220504031",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-824.82",
                FITID: "20220504032",
                CHECKNUM: "20220504032",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-150.60",
                FITID: "20220504033",
                CHECKNUM: "20220504033",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-150.93",
                FITID: "20220504034",
                CHECKNUM: "20220504034",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-130.55",
                FITID: "20220504035",
                CHECKNUM: "20220504035",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-140.74",
                FITID: "20220504036",
                CHECKNUM: "20220504036",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-137.51",
                FITID: "20220504037",
                CHECKNUM: "20220504037",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-141.52",
                FITID: "20220504038",
                CHECKNUM: "20220504038",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-172.59",
                FITID: "20220504039",
                CHECKNUM: "20220504039",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-151.10",
                FITID: "20220504040",
                CHECKNUM: "20220504040",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-206.17",
                FITID: "20220504041",
                CHECKNUM: "20220504041",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-150.81",
                FITID: "20220504042",
                CHECKNUM: "20220504042",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-142.76",
                FITID: "20220504043",
                CHECKNUM: "20220504043",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-145.32",
                FITID: "20220504044",
                CHECKNUM: "20220504044",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-206.17",
                FITID: "20220504045",
                CHECKNUM: "20220504045",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-219.02",
                FITID: "20220504046",
                CHECKNUM: "20220504046",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-206.17",
                FITID: "20220504047",
                CHECKNUM: "20220504047",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-124.35",
                FITID: "20220504048",
                CHECKNUM: "20220504048",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-142.99",
                FITID: "20220504049",
                CHECKNUM: "20220504049",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-259.11",
                FITID: "20220504050",
                CHECKNUM: "20220504050",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-422.42",
                FITID: "20220504051",
                CHECKNUM: "20220504051",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-139.81",
                FITID: "20220504052",
                CHECKNUM: "20220504052",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-132.18",
                FITID: "20220504053",
                CHECKNUM: "20220504053",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-176.17",
                FITID: "20220504054",
                CHECKNUM: "20220504054",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-327.26",
                FITID: "20220504055",
                CHECKNUM: "20220504055",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-526.62",
                FITID: "20220504056",
                CHECKNUM: "20220504056",
                MEMO: "SISPAG TRIB MUNICIPAL",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-2250.00",
                FITID: "20220504057",
                CHECKNUM: "20220504057",
                MEMO: "SISPAG SALARIOS",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-7681.23",
                FITID: "20220504058",
                CHECKNUM: "20220504058",
                MEMO: "SISPAG BOLET OUTR BCO",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-243.53",
                FITID: "20220504059",
                CHECKNUM: "20220504059",
                MEMO: "SISPAG BOLET OUTR BCO",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-237.46",
                FITID: "20220504060",
                CHECKNUM: "20220504060",
                MEMO: "SISPAG BOLET OUTR BCO",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-155.27",
                FITID: "20220504061",
                CHECKNUM: "20220504061",
                MEMO: "SISPAG BOLET OUTR BCO",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-207087.42",
                FITID: "20220504062",
                CHECKNUM: "20220504062",
                MEMO: "SISPAG BOLET OUTR BCO",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-2527.15",
                FITID: "20220504063",
                CHECKNUM: "20220504063",
                MEMO: "SISPAG BOLET OUTR BCO",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-2914.22",
                FITID: "20220504064",
                CHECKNUM: "20220504064",
                MEMO: "SISPAG BOLET OUTR BCO",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "-13.20",
                FITID: "20220504065",
                CHECKNUM: "20220504065",
                MEMO: "TAR/CUSTAS COBRANCA",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "28088.00",
                FITID: "20220504066",
                CHECKNUM: "20220504066",
                MEMO: "DEPOSITO DINHEIRO",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "709.30",
                FITID: "20220504067",
                CHECKNUM: "20220504067",
                MEMO: "SISPAG AJTC MUDANCAS E",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "40.95",
                FITID: "20220504068",
                CHECKNUM: "20220504068",
                MEMO: "SISPAG IVAN B C ASSOCIA",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "2720.00",
                FITID: "20220504069",
                CHECKNUM: "20220504069",
                MEMO: "TED 237.0000BANCO BRADES",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "129.93",
                FITID: "20220504070",
                CHECKNUM: "20220504070",
                MEMO: "TED 237.0099INST ESTUDOS",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "431.99",
                FITID: "20220504071",
                CHECKNUM: "20220504071",
                MEMO: "TED 237.0099INST ESTUDOS",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "143.30",
                FITID: "20220504072",
                CHECKNUM: "20220504072",
                MEMO: "TED 237.0099INST ESTUDOS",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "235.91",
                FITID: "20220504073",
                CHECKNUM: "20220504073",
                MEMO: "TED 237.0099INST ESTUDOS",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "129.93",
                FITID: "20220504074",
                CHECKNUM: "20220504074",
                MEMO: "TED 237.0099INST ESTUDOS",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "774.63",
                FITID: "20220504075",
                CHECKNUM: "20220504075",
                MEMO: "TED 237.0099INST ESTUDOS",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "93978.48",
                FITID: "20220504076",
                CHECKNUM: "20220504076",
                MEMO: "TED 237.1901JOAO BATISTA",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "386476.01",
                FITID: "20220504077",
                CHECKNUM: "20220504077",
                MEMO: "TED 237.19011 TABEL PROT",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "655.60",
                FITID: "20220504078",
                CHECKNUM: "20220504078",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "152.54",
                FITID: "20220504079",
                CHECKNUM: "20220504079",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "4971.90",
                FITID: "20220504080",
                CHECKNUM: "20220504080",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "2176.38",
                FITID: "20220504081",
                CHECKNUM: "20220504081",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "3268.14",
                FITID: "20220504082",
                CHECKNUM: "20220504082",
                MEMO: "TED 237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "114.92",
                FITID: "20220504083",
                CHECKNUM: "20220504083",
                MEMO: "PIX TRANSF ANTONIO04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "33.55",
                FITID: "20220504084",
                CHECKNUM: "20220504084",
                MEMO: "PIX TRANSF CESAR A04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "52.58",
                FITID: "20220504085",
                CHECKNUM: "20220504085",
                MEMO: "PIX TRANSF Diogo M04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "577.73",
                FITID: "20220504086",
                CHECKNUM: "20220504086",
                MEMO: "PIX TRANSF EDIMILS04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "155.99",
                FITID: "20220504087",
                CHECKNUM: "20220504087",
                MEMO: "PIX TRANSF EDSON P04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "470.81",
                FITID: "20220504088",
                CHECKNUM: "20220504088",
                MEMO: "PIX TRANSF EURICO 04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "52.58",
                FITID: "20220504089",
                CHECKNUM: "20220504089",
                MEMO: "PIX TRANSF FRANCIE04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "600.98",
                FITID: "20220504090",
                CHECKNUM: "20220504090",
                MEMO: "PIX TRANSF JACQUEL04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "479.35",
                FITID: "20220504091",
                CHECKNUM: "20220504091",
                MEMO: "PIX TRANSF JEANDRO04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "2386.19",
                FITID: "20220504092",
                CHECKNUM: "20220504092",
                MEMO: "PIX TRANSF JOSE ER04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "105.16",
                FITID: "20220504093",
                CHECKNUM: "20220504093",
                MEMO: "PIX TRANSF LUCAS V04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "430.86",
                FITID: "20220504094",
                CHECKNUM: "20220504094",
                MEMO: "PIX TRANSF Mauro P04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "1345.67",
                FITID: "20220504095",
                CHECKNUM: "20220504095",
                MEMO: "PIX TRANSF MARCO A04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "191.25",
                FITID: "20220504096",
                CHECKNUM: "20220504096",
                MEMO: "PIX TRANSF MMEI LO04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "557.18",
                FITID: "20220504097",
                CHECKNUM: "20220504097",
                MEMO: "PIX TRANSF MULTIPL04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "450.48",
                FITID: "20220504098",
                CHECKNUM: "20220504098",
                MEMO: "PIX TRANSF Priscil04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "2526.90",
                FITID: "20220504099",
                CHECKNUM: "20220504099",
                MEMO: "PIX TRANSF Roselia04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "331.01",
                FITID: "20220504100",
                CHECKNUM: "20220504100",
                MEMO: "PIX TRANSF RIBAMAR04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "230.72",
                FITID: "20220504101",
                CHECKNUM: "20220504101",
                MEMO: "PIX TRANSF SIMONE 04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "311.58",
                FITID: "20220504102",
                CHECKNUM: "20220504102",
                MEMO: "PIX TRANSF UILIAN 04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "525.02",
                FITID: "20220504103",
                CHECKNUM: "20220504103",
                MEMO: "PIX TRANSF VALDIRE04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "114.92",
                FITID: "20220504104",
                CHECKNUM: "20220504104",
                MEMO: "PIX TRANSF VITOR V04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "716.12",
                FITID: "20220504105",
                CHECKNUM: "20220504105",
                MEMO: "PIX TRANSF WILLIAN04/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220504100000[-03:EST]",
                TRNAMT: "15319.71",
                FITID: "20220504106",
                CHECKNUM: "20220504106",
                MEMO: "MOV TIT COB DISP 04/05S",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "-61.20",
                FITID: "20220505001",
                CHECKNUM: "20220505001",
                MEMO: "TAR/CUSTAS COBRANCA",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "165850.50",
                FITID: "20220505002",
                CHECKNUM: "20220505002",
                MEMO: "MOV TIT COB DISP 05/05S",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "-767.09",
                FITID: "20220505003",
                CHECKNUM: "20220505003",
                MEMO: "SISPAG FORNECEDORES",
              },
              {
                TRNTYPE: "DEBIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "-739.49",
                FITID: "20220505004",
                CHECKNUM: "20220505004",
                MEMO: "SISPAG TRIBUTOS",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "156.44",
                FITID: "20220505005",
                CHECKNUM: "20220505005",
                MEMO: "PIX TRANSF CRISTIA05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "792.97",
                FITID: "20220505006",
                CHECKNUM: "20220505006",
                MEMO: "PIX TRANSF SILVANA05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "2240.37",
                FITID: "20220505007",
                CHECKNUM: "20220505007",
                MEMO: "PIX TRANSF DENIS F05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "288.32",
                FITID: "20220505008",
                CHECKNUM: "20220505008",
                MEMO: "PIX TRANSF LUCAS D05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "713.05",
                FITID: "20220505009",
                CHECKNUM: "20220505009",
                MEMO: "PIX TRANSF SIND TR05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "162.58",
                FITID: "20220505010",
                CHECKNUM: "20220505010",
                MEMO: "PIX TRANSF MARCELO05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "487.97",
                FITID: "20220505011",
                CHECKNUM: "20220505011",
                MEMO: "PIX TRANSF SMART N05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "398.73",
                FITID: "20220505012",
                CHECKNUM: "20220505012",
                MEMO: "PIX TRANSF L.A DIS05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "33.55",
                FITID: "20220505013",
                CHECKNUM: "20220505013",
                MEMO: "TED 00237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "494.89",
                FITID: "20220505014",
                CHECKNUM: "20220505014",
                MEMO: "TED 00237.2268IEPTB MG",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "141.24",
                FITID: "20220505015",
                CHECKNUM: "20220505015",
                MEMO: "PIX TRANSF SILVETE05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "129.93",
                FITID: "20220505016",
                CHECKNUM: "20220505016",
                MEMO: "TED 0237.0099INSTITUTO",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "140.99",
                FITID: "20220505017",
                CHECKNUM: "20220505017",
                MEMO: "TED 0237.0099INSTITUTO",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "129.93",
                FITID: "20220505018",
                CHECKNUM: "20220505018",
                MEMO: "TED 0237.0099INSTITUTO",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "209.09",
                FITID: "20220505019",
                CHECKNUM: "20220505019",
                MEMO: "SISPAG PEREIRA & GONCAL",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "167.50",
                FITID: "20220505020",
                CHECKNUM: "20220505020",
                MEMO: "PIX TRANSF THAYS S05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "448.82",
                FITID: "20220505021",
                CHECKNUM: "20220505021",
                MEMO: "TED 237.0000BANCO BRAD",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "236.16",
                FITID: "20220505022",
                CHECKNUM: "20220505022",
                MEMO: "PIX TRANSF Thiago 05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "115.73",
                FITID: "20220505023",
                CHECKNUM: "20220505023",
                MEMO: "PIX TRANSF ALEX PI05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "557.62",
                FITID: "20220505024",
                CHECKNUM: "20220505024",
                MEMO: "PIX TRANSF EBERLEI05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "33.55",
                FITID: "20220505025",
                CHECKNUM: "20220505025",
                MEMO: "PIX TRANSF MARCELO05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "127.38",
                FITID: "20220505026",
                CHECKNUM: "20220505026",
                MEMO: "PIX TRANSF GABRIEL05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "218.53",
                FITID: "20220505027",
                CHECKNUM: "20220505027",
                MEMO: "PIX TRANSF PAULA C05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "52.58",
                FITID: "20220505028",
                CHECKNUM: "20220505028",
                MEMO: "PIX TRANSF MARIA A05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "316.54",
                FITID: "20220505029",
                CHECKNUM: "20220505029",
                MEMO: "PIX TRANSF WEBER A05/05",
              },
              {
                TRNTYPE: "CREDIT",
                DTPOSTED: "20220505100000[-03:EST]",
                TRNAMT: "619.99",
                FITID: "20220505030",
                CHECKNUM: "20220505030",
                MEMO: "SISPAG GP EMPREENDIMENT",
              },
            ],
          },
          LEDGERBAL: { BALAMT: "691324.96", DTASOF: "20220505100000[-03:EST]" },
        },
      },
      header: {
        OFXHEADER: "100",
        DATA: "OFXSGML",
        VERSION: "102",
        SECURITY: "NONE",
        ENCODING: "USASCII",
        CHARSET: "1252",
        COMPRESSION: "NONE",
        OLDFILEUID: "NONE",
        NEWFILEUID: "NONE",
      },
    },
    ieptb_total: 2139830,
  },
  {
    name: "Banco do Brasil",
    agency: "1901",
    number: "83333-9",
    ofx: "[object File]",
    ofx_result: {
      OFX: {
        SIGNONMSGSRSV1: {
          SONRS: {
            STATUS: { CODE: "0", SEVERITY: "INFO" },
            DTSERVER: "00000000000000",
            LANGUAGE: "POR",
          },
        },
        BANKMSGSRSV1: {
          CURDEF: "BRL",
          BANKACCTFROM: {
            BANKID: "0237",
            ACCTID: "83333",
            ACCTTYPE: "CHECKING",
          },
          BANKTRANLIST: { DTSTART: "20220914120000", DTEND: "20220914120000" },
          LEDGERBAL: { BALAMT: "-283,72", DTASOF: "00000000" },
        },
      },
      header: {
        OFXHEADER: "100",
        DATA: "OFXSGML",
        VERSION: "102",
        SECURITY: "NONE",
        ENCODING: "USASCII",
        CHARSET: "1252",
        COMPRESSION: "NONE",
        OLDFILEUID: "NONE",
        NEWFILEUID: "NONE",
      },
    },
    ieptb_total: 0,
  },
  {
    name: "Banco Bradesco S.A",
    agency: "1901",
    number: "53410-2",
    ofx: "[object File]",
    ofx_result: {
      OFX: {
        SIGNONMSGSRSV1: {
          SONRS: {
            STATUS: { CODE: "0", SEVERITY: "INFO" },
            DTSERVER: "00000000000000",
            LANGUAGE: "POR",
          },
        },
        BANKMSGSRSV1: {
          CURDEF: "BRL",
          BANKACCTFROM: {
            BANKID: "0237",
            ACCTID: "83333",
            ACCTTYPE: "CHECKING",
          },
          BANKTRANLIST: { DTSTART: "20220914120000", DTEND: "20220914120000" },
          LEDGERBAL: { BALAMT: "-283,72", DTASOF: "00000000" },
        },
      },
      header: {
        OFXHEADER: "100",
        DATA: "OFXSGML",
        VERSION: "102",
        SECURITY: "NONE",
        ENCODING: "USASCII",
        CHARSET: "1252",
        COMPRESSION: "NONE",
        OLDFILEUID: "NONE",
        NEWFILEUID: "NONE",
      },
    },
    ieptb_total: 0,
  },
];
