import { isEqual } from "lodash";
import { ComponentOptionsMixin, defineComponent } from "vue";

export const FormBindind: ComponentOptionsMixin = defineComponent({
  props: ["modelValue"],
  methods: {
    setData() {
      if (!isEqual(this.form, this.modelValue))
        Object.assign(this.form, this.modelValue);
    },
  },
  created() {
    this.setData();
  },
  watch: {
    modelValue() {
      this.setData();
    },
    form: {
      deep: true,
      handler() {
        if (!isEqual(this.form, this.modelValue))
          this.$emit("update:modelValue", Object.assign({}, this.form));
      },
    },
  },
});
