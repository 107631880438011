export const brl = (value: number): string => {
  const brlFormat = {
    style: "currency",
    currency: "BRL",
    // maximumFractionDigits: 2,
  };

  const brlFormatter = new Intl.NumberFormat("pt-BR", brlFormat);

  return (
    (value < 0 ? "-" : "") +
    brlFormatter
      .format(value)
      .replace(/[^0-9R$,.]/g, " ")
      .trim()
  );
};
