import router from "@/router";
import store from "@/store";
// import { ICreateUser } from "@/interfaces/create-user.interface";
import { api } from "./api";

export const LogIn = async (payload: { email: string; password: string }) => {
  const res = await api.post("/auth/login", payload);
  if (res?.data?.token) {
    localStorage.setItem("tk", res?.data?.token);
    localStorage.lastTkDate = Date.now();
  }
  await getMe();
  return res;
};

export const getMe = async () => {
  if (
    !localStorage.tk ||
    !localStorage.lastTkDate ||
    Date.now() > localStorage.lastTkDate + 172800000 ||
    store.state.auth.user?.name
  )
    throw new Error("User is not logged");

  try {
    const res = await api.get("/user/me");
    await store.commit("auth/SET_USER", res.data);

    return res;
  } catch (err) {
    localStorage.removeItem("tk");
    router.push("/login");
  }
};

export const checkEmail = async (email: string) => {
  const { data } = await api.get("/user/check-email", { params: { email } });
  return data.exists;
};
