import { ICreateAudit } from "@/interfaces/create-audit.interface";
import { api } from "./api";

export const createAudit = async (payload: ICreateAudit) => {
  const { data } = await api.post("audit", payload);
  return data;
};

export const getAudit = async () => {
  const { data } = await api.get("audit");
  return data;
};

export const getValidation = async () => {
  const { data } = await api.get("audit/validation");
  return data;
};

export const approveAudit = (id: string) => {
  return api.post("audit/approve/" + id);
};

export const refuseAudit = (id: string) => {
  return api.post("audit/refuse/" + id);
};
