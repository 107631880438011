import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API;

axios.interceptors.request.use(async function (config) {
  if (!config.headers) config.headers = {};

  config.headers["Content-Type"] = "application/json";
  const token = localStorage.tk;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const http = axios;
