import { cpf, cnpj } from "cpf-cnpj-validator";
import cc from "card-validator";

type Rule = (v: string) => string | true;

const calcAge = (date: Date) => {
  return ~~((Date.now() - date.getTime()) / 31557600000);
};

const toDate = (v: string) => new Date(v.split("/").reverse().join("-"));

const date: Rule = (v: string) =>
  (v.split("/").length === 3 &&
    v.length === 10 &&
    toDate(v).toString() !== "Invalid Date") ||
  "";

export const rules: Record<string, Rule | Rule[]> = {
  required: (v) => (v && v.trim && !!v.trim()) || "Campo obrigatório",
  email: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value.trim()) || "E-mail inválido.";
  },
  cpf: (v) => cpf.isValid(v) || "Digite um CPF válido",
  cnpj: (v) => cnpj.isValid(v) || "Digite um CNPJ válido",
  document_number: (v) => cnpj.isValid(v) || cpf.isValid(v) || "Inválido",
  password: [
    (v) => v.length >= 8 || "Sua senha precisa ter 8 catacteres ou mais",
    (v) => !!v.match(/[0-9]/) || "Sua senha precisa ter no minimo um numero",
    (v) =>
      !!v.match(/[A-Z]/) ||
      "Sua senha precisa ter no minimo uma letra maiúscula",
  ],
  credit_card: (v) => cc.number(v).isValid || "Informe um número válido",
  name: (v) => v.split(" ").length >= 2 || "Informe o nome corretamente",
  phone: (v) => (v && v.replace(/[^0-9]/g, "").length === 11) || "Inválido",
  date: (v: string) => (v && date(v)) || "Informe um data válida",
  _18yOld: (v: string) =>
    calcAge(toDate(v)) >= 18 || "Precisa ser maior de 18 anos",
  valid_date: (v) => {
    const date = v.split("/");
    return (
      new Date(`20${date[1]}-${date[0]}-01`) > new Date() || "Data inválida"
    );
  },
};
