<template>
  <v-form @submit.prevent="next" class="pa-4" ref="form">
    <p>
      Selecione um arquivo de <b>atos praticados</b> referênte á data da
      conciliação. Certifique-se de que o arquvo contém as transações de uma
      única data.
    </p>

    <input
      type="file"
      placeholder="Selecione a planilha de atos praticados"
      accept=".csv"
      class="my-16"
      ref="atos"
      @change="readCsv"
      :disabled="loading"
    />
    {{ selectedRows.length }}
    <!-- <div v-if="resume.date">
      <b>Data:</b>
      <span>{{ resume.date }} </span>
    </div> -->
    <!-- <v-text-field
      v-mask="'##/##/####'"
      label="Digite uma data"
      :rules="[rules.date]"
      v-model="form.date"
      variant="outlined"
    />
    <div class="mt-6">
      <b class="text-right pr-4" width="1%"> Total IEPTB em {{ form.date }} </b>
      <span class="text-left">{{ brl(totalIeptb / 100) }}</span>
    </div> -->
    <div class="mt-16 d-flex">
      <v-btn color="primary" size="large" @click="$emit('prev')" variant="text">
        <v-icon>ph-arrow-left</v-icon>
        <span>Voltar</span>
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        size="large"
        type="submit"
        :loading="loading"
        :disabled="!resume.date"
      >
        <span>Próximo</span>
        <v-icon>ph-arrow-right</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { FormBindind } from "../../mixins/form";
import { csv2Json } from "../../services/csv";
import { rules } from "../../utils/rules";
import { brl } from "../../utils/brl";
import { mask } from "vue-the-mask";
import { loadLot } from "../../services/lot";

export default {
  name: "FinancialCloserStep3",
  mixins: [FormBindind],
  directives: { mask },
  data() {
    return {
      brl,
      rules,
      form: {
        date: "",
        atos: "",
        banks: [],
      },
      resume: {
        date: "",
      },
      loading: false,
    };
  },
  methods: {
    async next() {
      if (!this.$refs.atos || !this.$refs.atos?.files[0])
        return alert("Insira o arquivo");

      this.$vloading.show();
      this.loading = true;
      console.log(this.selectedRows);
      await loadLot(
        this.selectedRows.map((a) => ({
          protocol: a.Protocolo,
          date: new Date(
            a["Data da Situação"].split("/").reverse().join("-") + " 00:00:00"
          ).toGMTString(),
          description: "#",
          raw: a,
          amount: this.fixValue(a.Total),
        }))
      );
      this.$vloading.hide();
      this.loading = false;
      this.$emit("next");
    },
    async readCsv() {
      this.loading = true;
      const result = await csv2Json(this.$refs.atos.files[0]);
      this.form.atos = result;
      this.resume.date = this.form.atos[5].Data;
      this.setBanks();
      this.loading = false;
    },
    setBanks() {
      const banks = {};

      window.aa = this.form.atos;
      for (let i = 0; i < this.form.atos.length; i++) {
        const item = this.form.atos[i];

        const key = item.Conta;

        if ((key && banks[key]) || !item.Banco) continue;
        banks[key] = {
          name: item.Banco,
          agency: item.Agencia,
          number: item.Conta,
        };
      }

      this.form.banks = Object.values(banks);
    },
    fixValue(v) {
      return Math.round(`${v}`.replace(/,/g, ".") * 100);
    },
  },
  computed: {
    totalIeptb() {
      return this.selectedRows.reduce(
        (r, item) => r + this.fixValue(item.Total),
        0
      );
    },
    selectedRows() {
      return (this.form.atos || []).filter(
        (a) => a.Origem.toUpperCase().indexOf("IEPTB") >= 0
      );
    },
  },
};
</script>

<style></style>
