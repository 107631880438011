export const getFileContent = async (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(
        (typeof reader.result === "string"
          ? reader.result
          : reader.result?.toString()) || ""
      );
    };
    reader.readAsText(file);
  });
};
